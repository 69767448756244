<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <b-link class="brand-logo">
        <logo
          :height="`35px`"
          :full="true"
        />
      </b-link>

      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            :alt="$t('Imagem')"
          />
        </div>
      </b-col>

      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >

          <!--
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            {{ $store.getters['auth/getConfigServer'].name }}
          </b-card-title>
          -->

          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              :autocomplete="`form-auth-${Date.now()}`"
              @submit.prevent="authLogin"
            >

              <b-form-group
                :label="$t('Email')"
                :label-for="`user-email-${formID}`"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('email')"
                  rules="required|email"
                >
                  <b-form-input
                    :id="`user-email-${formID}`"
                    v-model="userEmail"
                    :name="`user-email-${formID}`"
                    placeholder=""
                    autocomplete="off"
                    autocorrect="off"
                    autocapitalize="off"
                    spellcheck="false"
                    type="email"
                    inputmode="email"
                    :state="errors.length > 0 ? false:null"
                    :tabindex="1"
                    :readonly="emailReadOnly"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                :label="$t('Password')"
                :label-for="`user-password-${formID}`"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('password')"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      :id="`user-password-${formID}`"
                      v-model="userPassword"
                      :name="`user-password-${formID}`"
                      class="form-control-merge"
                      placeholder=""
                      autocomplete="new-password"
                      autocorrect="off"
                      autocapitalize="off"
                      spellcheck="false"
                      :state="errors.length > 0 ? false:null"
                      :type="passwordFieldType"
                      :tabindex="2"
                      :readonly="passwordReadOnly"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>

                  <div class="d-flex justify-content-end mt-1 mb-1">
                    <b-link
                      :to="{name:'auth-forgot-password'}"
                    >
                      <small>{{ $t('Esqueceste-te da tua password?') }}</small>
                    </b-link>
                  </div>

                </validation-provider>
              </b-form-group>

              <b-form-group
                v-if="listCountryUser.length"
                :label-for="`user-country-${formID}`"
                :label="$t('País')"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('país')"
                  rules="required"
                >
                  <v-select
                    :id="`user-country-${formID}`"
                    v-model="userCountry"
                    :name="`user-country-${formID}`"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="listCountryUser"
                    :state="errors.length > 0 ? false:null"
                    label="text"
                    :tabindex="4"
                  >
                    <template #option="{ text, icon }">
                      <feather-icon
                        :icon="icon"
                        size="16"
                        class="align-middle mr-50"
                      />
                      <span> {{ text }}</span>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                variant="primary"
                type="submit"
                block
                :disabled="invalid || loadingSubmit"
                :tabindex="5"
              >
                <b-spinner
                  v-if="loadingSubmit===true"
                  small
                />
                <span v-if="loadingSubmit===true">&nbsp;{{ $t('Aguarde...') }}</span>
                <span v-if="loadingSubmit===false">&nbsp;{{ $t('Entrar') }}</span>
              </b-button>

            </b-form>
          </validation-observer>

        </b-col>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import logo from '@core-custom/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BImg, BForm, BButton, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required, email } from '@validations-custom'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { Geolocation } from '@capacitor/geolocation'
import { VeeValidateLoadLocale } from '@core-custom/mixins/geral'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BImg,
    BForm,
    BButton,
    BSpinner,
    logo,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  mixins: [togglePasswordVisibility, VeeValidateLoadLocale],
  data() {
    return {
      userEmail: '',
      userPassword: '',
      userCountry: '',
      required,
      email,
      listCountryUser: [],
      loadingSubmit: false,
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      emailReadOnly: false,
      passwordReadOnly: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    formID() {
      return Date.now()
    },
  },
  methods: {
    async authLogin() {
      this.loadingSubmit = true
      const getLocation = async () => {
        try {
          const position = await Geolocation.getCurrentPosition({
            enableHighAccuracy: true,
            maximumAge: 3000,
            timeout: 5000,
          })

          return {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          }
        } catch (error) {
          return null
        }
      }

      getLocation().then(location => {
        this.$refs.loginForm.validate().then(success => {
          if (success) {
            let sGeoApp = null

            if (location) {
              const { latitude, longitude } = location
              if (!!latitude && !!longitude) {
                sGeoApp = `${latitude}#${longitude}`
              }
            }

            const formData = new FormData()
            formData.append('email', this.userEmail)
            formData.append('password', this.userPassword)
            formData.append('geoApp', sGeoApp)
            if (this.userCountry.value) {
              formData.append('country', this.userCountry.value)
            }

            this.$store
              .dispatch('auth/login', formData)
              .then(response => {
                if (Object.keys(response.configMobile).length > 1) {
                  this.listCountryUser = []
                  const listCountry = Object.keys(response.configMobile)
                  listCountry.forEach(element => {
                    this.listCountryUser.push({
                      text: response.configMobile[element].countryApp,
                      value: response.configMobile[element].country,
                      icon: 'GlobeIcon',
                    })
                  })
                } else if (Object.keys(response.configMobile).length === 1) {
                  const responseAuth = response.configMobile[Object.keys(response.configMobile)[0]]

                  if ((responseAuth.error === 1) && (responseAuth.msg !== '')) {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: this.$t('Alerta'),
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: responseAuth.msg,
                      },
                    })
                  } else if (!!response.accessToken && !!response.refreshToken) {
                    this.$store.dispatch('auth/loginUser', response).then(() => {
                      this.$router.push({ name: 'dashboard' })
                    })
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: this.$t('Alerta'),
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: this.$t('Não foi possível obter o token de autenticação'),
                      },
                    })
                  }
                }

                this.loadingSubmit = false
              })
              .catch(responseError => {
                if ((responseError.error === 1) && (responseError.msg !== '')) {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: this.$t('Alerta'),
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                      text: responseError.msg,
                    },
                  })
                }
                this.loadingSubmit = false
              })
          } else {
            this.loadingSubmit = false
          }
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
